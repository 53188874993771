import React from 'react';
import Button from '../../components/buttons/button';

const AppMobile = () => {
  return (
    <div className="min-h-screen flex flex-row items-center justify-center">
      <div>
        <h2 className="text-5xl text-center text-grey-dark font-hairline font-heading">
          Patronscan Mobile App
        </h2>
        <div className="flex flex-col justify-center items-center">
          <Button
            link="https://secure.servalldatasystems.com/hotscan/pimobile/app-release.apk"
            internal={false}
            className="mx-auto mb-0 w-full"
            blue
            text="Install for Android"
          />
          <Button
            link="https://secure.servalldatasystems.com/hotscan/pimobile/download.html"
            internal={false}
            className="mx-auto w-full"
            blue
            text="Install for iOS"
          />
        </div>
      </div>
    </div>
  );
};

export default AppMobile;
